<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Upload Dokumen'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="pabrik_id_label"
                  label="Pabrik :"
                  label-for="pabrik_id"
                >
                  <b-form-select
                    size="sm"
                    id="pabrik"
                    v-model="form.pabrik_id"
                    :options="pabrik"
                    @input="choosePabrik"
                    required
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="plant_id_label"
                  label="Plant :"
                  label-for="plant_id"
                >
                  <b-form-select
                    id="plant_id"
                    v-model="form.plant_id"
                    :options="plant"
                  ></b-form-select>
                  <!-- <vue-suggestion
                    id="plant_id"
                    :items="plant"
                    v-model="ItemPlant"
                    :setLabel="setLabel"
                    :itemTemplate="itemTemplate"
                    @changed="inputChangePlant"
                    @selected="choosePlant"
                    inputClasses="form-control"
                    placeholder="Enter Text..."
                    size="sm"
                    required
                  >
                  </vue-suggestion> -->
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="equipment_id_label"
                  label="Equipment :"
                  label-for="equipment_id"
                >
                  <vue-suggestion
                    id="equipment_id"
                    :items="equipment"
                    v-model="ItemEquipment"
                    :setLabel="setLabel"
                    :itemTemplate="itemTemplate"
                    @changed="inputChangeEquipment"
                    @selected="chooseEquipment"
                    inputClasses="form-control"
                    placeholder="Enter Text..."
                    required
                    size="sm"
                  >
                  </vue-suggestion>
                </b-form-group>
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="jenis_dokumen_label"
                  label="Jenis Dokumen :"
                  label-for="jenis_dokumen"
                >
                  <b-form-select
                    id="jenis_dokumen"
                    v-model="form.jenis_dokumen"
                    :options="jenis_dokumen"
                    required
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="judul_dokumen_label"
                  label="Judul Dokumen: "
                  label-for="judul_dokumen"
                >
                  <b-form-input
                    id="judul_dokumen"
                    v-model="form.judul_dokumen"
                    type="text"
                    placeholder="Enter Text..."
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="nomor_dokumen_label"
                  label="Nomor Dokumen :"
                  label-for="nomor_dokumen"
                >
                  <b-form-input
                    id="nomor_dokumen"
                    v-model="form.nomor_dokumen"
                    type="text"
                    placeholder="Enter Text..."
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  label="Versi Dokumen :"
                  label-for="versi_dokumen"
                >
                  <b-form-input
                    id="versi_dokumen"
                    v-model="form.versi_dokumen"
                    type="number"
                    placeholder="Enter Text..."
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="tanggal_label"
                  label="Tanggal Pembuatan: "
                  label-for="tanggal"
                >
                  <b-form-input
                    id="tanggal"
                    v-model="form.tanggal"
                    type="date"
                    placeholder="Enter Date..."
                    required
                  ></b-form-input>
                </b-form-group>

                <!-- <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="versi_dokumen_label"
                  label="Nomor Equipment :"
                  label-for="versi_dokumen"
                >
                  <b-form-input
                    id="versi_dokumen"
                    v-model="form.versi_dokumen"
                    type="text"
                    placeholder="Enter Text..."
                    required
                  ></b-form-input>
                </b-form-group> -->

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="file_label"
                  label="File :"
                  label-for="versi_dokumen"
                  description="File : PDF, Image. Ukuran Max : 1 Gb"
                >
                  <b-form-file
                    id="file"
                    v-model="form.file"
                    accept=".jpeg, .png, .jpg, .pdf, .JPG, .mkv"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    @input="checkFilesize"
                    ref="file-input"
                    required
                  ></b-form-file>
                </b-form-group>

                <b-progress
                  :value="progress"
                  :label="`${progress}%`"
                  :max="100"
                  show-progress
                  animated
                ></b-progress>

                <br />
                <hr />

                <b-button
                  class="mt-6"
                  variant="outline-dark"
                  :disabled="loading"
                  @click="$router.push('/datadesain')"
                >
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button
                  class="ml-3 mt-6"
                  type="submit"
                  variant="outline-success"
                  :disabled="loading"
                >
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>
              </b-form>
            </b-col>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapState } from 'vuex'

import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'
import $ from 'jquery'

import itemTemplate from '@/view/pages/item-template.vue'

export default {
  mixins: [validationMixin],
  name: 'addDataDesain',
  data() {
    return {
      form: {
        judul_dokumen: null,
        nomor_dokumen: null,
        jenis_dokumen: null,
        tanggal: null,
        versi_dokumen: null,
        file: null,
        equipment_id: null,
        pabrik_id: null,
        plant_id: null,
      },
      equipment: [],
      pabrik: [],
      plant: [],

      ItemEquipment: null,
      // ItemPlant: null,
      ItemPart: null,
      itemTemplate,

      progress: 0,

      jenis_dokumen: [
        { value: null, text: '-- Pilih Jenis Dokumen --' },
        { value: 'PFD', text: 'PFD' },
        { value: 'P&ID', text: 'P&ID' },
        { value: 'Piping Isometric', text: 'Piping Isometric' },
        { value: 'Equipment Drawing', text: 'Equipment Drawing' },
        { value: 'Equipment Datasheet', text: 'Equipment Datasheet' },
        { value: 'Inspection Form', text: 'Inspection Form' },
        { value: 'Instruksi Kerja', text: 'Instruksi Kerja' },
      ],

      loading: false,
    }
  },
  components: {
    KTCodePreview,
  },
  created() {
    ApiService.setHeader()

    this.loadPabrik()
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Data Desain', route: '/datadesain' },
      { title: 'Upload Dokumen' },
    ])
  },
  methods: {
    checkFilesize() {
      const file = this.form.file

      if (!file) {
        return
      }

      if (file.size > 1024000 * 1024000) {
        this.makeToast('warning', 'Ukuran file terlalu besar.')
        this.$refs['file-input'].reset()
      }
    },
    onSubmit(event) {
      event.preventDefault()

      var self = this
      self.loading = true
      const judul_dokumen = self.form.judul_dokumen
      const nomor_dokumen = self.form.nomor_dokumen
      const jenis_dokumen = self.form.jenis_dokumen
      const tanggal = self.form.tanggal
      const versi_dokumen = self.form.versi_dokumen
      const file = self.form.file
      const pabrik_id = self.form.pabrik_id
      const equipment_id = self.form.equipment_id
      const plant_id = self.form.plant_id

      const dataku = new FormData()
      dataku.append('judul_dokumen', judul_dokumen)
      dataku.append('nomor_dokumen', nomor_dokumen)
      dataku.append('jenis_dokumen', jenis_dokumen)
      dataku.append('tanggal', tanggal)
      dataku.append('versi_dokumen', versi_dokumen)
      dataku.append('pabrik_id', pabrik_id)
      dataku.append('equipment_id', equipment_id)
      dataku.append('plant_id', plant_id)
      if (file) {
        dataku.append('file', file)
      }
      var urlApi = ApiService.urlApi()

      $.ajax({
        xhr: function() {
          var xhr = new window.XMLHttpRequest()

          xhr.upload.addEventListener(
            'progress',
            function(evt) {
              if (evt.lengthComputable) {
                var percentComplete = evt.loaded / evt.total
                percentComplete = parseInt(percentComplete * 100)
                self.progress = percentComplete

                // if (percentComplete === 100) {
                // }
              }
            },
            false
          )

          return xhr
        },
        url: urlApi + '/datadesain/store',
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
        headers: ApiService.headerAPi(),
        success: function(response) {
          if (response.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
            self.$router.push('/datadesain')
          }
        },
      })
    },
    setLabel(item) {
      return item.name
    },
    inputChangeEquipment(text) {
      // your search method
      var self = this
      var url =
        '/master/equipment/showequipment/' +
        text +
        '/' +
        this.form.pabrik_id +
        '/' +
        this.form.plant_id
      /*if (this.form.pabrik_id && text) {
        url += "/" + this.form.pabrik_id;
      }

      if (this.form.plant_id && text) {
        url += "/" + this.form.plant_id;
      }*/

      ApiService.get(url)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var equipment = data.data

            self.equipment.splice(0, self.equipment.length)
            for (let i = 0; i < equipment.length; i++) {
              self.equipment.push({
                id: equipment[i].id_equipment,
                name: equipment[i].nama_equipment + ' (' + equipment[i].nomor_equipment + ')',
                plant_id: equipment[i].plant_id,
                pabrik_id: equipment[i].pabrik_id,
                pabrik: equipment[i].nama_pabrik,
                plant: equipment[i].nama_plant,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    // inputChangePlant(text) {
    //   // your search method
    //   var self = this;
    //   var url = "/master/plant/showplant/" + text;
    //   if (this.form.pabrik_id && text) {
    //     url += "/" + this.form.pabrik_id;
    //   }

    //   ApiService.get(url)
    //     .then(({ data }) => {
    //       if (data.status == "ok") {
    //         var plant = data.data;

    //         self.plant.splice(0, self.plant.length);
    //         for (let i = 0; i < plant.length; i++) {
    //           self.plant.push({
    //             id: plant[i].id_plant,
    //             name: plant[i].nama_plant,
    //             pabrik: plant[i].nama_pabrik,
    //             pabrik_id: plant[i].pabrik_id,
    //           });
    //         }
    //       }
    //     })
    //     .catch(response => {
    //       console.log(response);
    //     });
    // },
    chooseEquipment: function(value) {
      this.form.equipment_id = value.id
      /*this.form.pabrik_id = value.pabrik_id;
      this.form.plant_id = value.plant_id;
      this.form.pabrik = value.pabrik;
      this.form.plant = value.plant;

      this.ItemPlant = {
        id: value.plant_id,
        name: value.plant,
        pabrik: value.pabrik,
        pabrik_id: value.pabrik_id,
      };

      this.choosePabrik();
      this.choosePlant();*/
    },
    choosePlant() {
      var param = {}
      if (this.form.plant_id) {
        this.pabrik.forEach(row => {
          if (row.value == this.form.pabrik_id) {
            param = row
          }
        })

        this.pabrik.splice(0, this.pabrik.length)
        this.pabrik.push(param)
      } else {
        this.loadPabrik()
      }
      // this.form.plant_id = value.id;
      // this.form.plant = value.nama_plant;
      // this.form.pabrik_id = value.pabrik_id;
    },
    loadPabrik() {
      var self = this
      this.pabrik.splice(0, this.pabrik.length)
      ApiService.get('/master/pabrik/all')
        .then(({ data }) => {
          if (data.status == 'ok') {
            var pabrik = data.data
            for (let i = 0; i < pabrik.length; i++) {
              self.pabrik.push({
                value: pabrik[i].id_pabrik,
                text: pabrik[i].nama_pabrik,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    choosePabrik() {
      var self = this
      ApiService.get(
        '/master/plant/all?page=1&pabrik_id=' +
          self.form.pabrik_id +
          '&sortBy=nama_plant&sortDesc=ASC'
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            // console.log(data);
            var plant = data.data.data
            self.plant.splice(0, self.plant.length)
            self.plant.push({
              value: null,
              text: '-- Pilih Plan --',
            })
            for (let i = 0; i < plant.length; i++) {
              self.plant.push({
                value: plant[i].id_plant,
                text: plant[i].nama_plant,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
    }),
  },
}
</script>
